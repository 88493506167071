<template>
    <div id="footer">
        <div class="footer">
            <div class="foot_wrap">
                <div class="logoPhone">
                    <div class="logo">
                        <img alt="logo" src="@/assets/logo2.png" />
                    </div>
                    <div class="phone">
                        <span class="text1">全国服务热线：</span>
                        <span class="text2">{{ $phone($store.state.phone) }}</span>
                        <span class="text3">生产基地（中国·广州）</span>
                    </div>
                </div>
                <div class="main_wrap">
                    <dl v-for="(item, index) in menuList" :key="index" style="cursor: ;">
                        <dt @click="menuClick(item.url)"
                            :style="{ 'cursor': index == 1 || index == 4 ? 'pointer' : 'auto' }">{{
                                item.name
                            }}</dt>
                        <dd class="line" v-if="index != menuList.length - 1 && index != 0"></dd>
                        <dd v-for="(cItem, cIndex) in item.childList" :key="cIndex"
                            @click="menuChildClick(cItem.url, index)">{{
                                cItem.name
                            }}</dd>
                    </dl>
                    <div class="qr_code">
                        <div class="tab">
                            <div class="item" @click="footIndex = 0">
                                <img v-show="footIndex !== 0" src="@/assets/images/wx.png" alt="" srcset="">
                                <img v-show="footIndex === 0" src="@/assets/images/wxSelect.png" alt="" srcset="">
                            </div>
                            <div class="item" @click="footIndex = 1">
                                <img v-show="footIndex !== 1" src="@/assets/images/xhs.png" alt="" srcset="">
                                <img v-show="footIndex === 1" src="@/assets/images/xhsSelect.png" alt="" srcset="">
                            </div>
                            <div class="item" @click="footIndex = 2">
                                <img v-show="footIndex !== 2" src="@/assets/images/dy.png" alt="" srcset="">
                                <img v-show="footIndex === 2" src="@/assets/images/dySelect.png" alt="" srcset="">
                            </div>
                        </div>
                        <div class="code">
                            <img alt="logo" v-lazy="qrCode[footIndex]" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom_line"></div>
            <div class="bottom_tip">
                © 版权所有 <span class="beian" @click="goWin">粤ICP备20058269号-5</span>
            </div>
        </div>
    </div>
</template>

<script>
import { getClassify } from "@/api/product";
import { getGongsi } from "@/api/about";
import { mapState, mapMutations } from "vuex";
export default {
    name: 'Footer',
    data() {
        return {
            menuList: [
                { name: '首页', url: '/home', childList: [] },
                { name: '关于爱幸福', url: '/brandStory', childList: [{ name: '品牌故事', url: '/brandStory' }, { name: '核心技术', url: '/coreTechnology' }, { name: '社会责任', url: '/responsibility' }, { name: '新闻中心', url: '/newsCenter' }, { name: '联系我们', url: '/contractUs' }] },
                { name: '爱幸福文化', url: '/brandCultrue', childList: [{ name: '象征价值', url: '/symbolicValue' }, { name: '呵护与精致', url: '/designer' }, { name: '家庭咨询', url: '/familyCounseling' }] },
                { name: '产品中心', url: '/product', childList: [{ name: '不锈钢整体橱柜', url: '/product' }, { name: '不锈钢全屋定制', url: '/product' }, { name: '不锈钢+实木定制', url: '/product' }, { name: '不锈钢+版式定制', url: '/product' }, { name: '版式全屋定制', url: '/product' }, { name: '软装装饰', url: '/product' }, { name: '装修', url: '/product' }, { name: '家电', url: '/product' }] },
                { name: '品牌活动', url: '/loveClass', url: '/product', childList: [{ name: '爱的课堂', url: '/loveClass' }, { name: '家庭日', url: '/familyDay' }, { name: '爱的分享', url: '/loveShare' }, { name: '爱力银行', url: '/loveBank' }] },
                { name: '加入爱幸福', url: '/joinIfami', childList: [] }
            ],
            qrCode: [],
            footIndex: 0,
        }
    },
    watch: {
        '$route'(to, from) {
            this.activeIndex = to.path;
            console.log(this.activeIndex)
        }
    },
    mounted() {
        this.getProductClass()
        this.loadCode()
    },
    methods: {
        ...mapMutations(['setClassifyVal']),
        loadCode() {
            getGongsi().then((res) => {
                if (res.code + '' === '200') {
                    let obj = res.data
                    this.qrCode = [obj.qrCode, obj.xhsCode, obj.dyCode]
                }
            });
        },
        getProductClass() {
            getClassify({
                type: 4,
                parentId: '',
                pageNum: 1,
                pageSize: 99
            }).then((res) => {
                if (res.code + '' === '200') {
                    let childList = []
                    for (let i = 0; i < res.data.length; i++) {
                        childList.push({
                            name: res.data[i].title,
                            url: `/product?did=${res.data[i].id}`
                        })
                    }
                    this.menuList[3].childList = childList
                    this.$forceUpdate()
                }
            });
        },
        menuClick(url) {
            if (!url) return;
            this.$router.push(url)
        },
        menuChildClick(url, index) {
            if (!url) return;
            if (index == 3) {
                let classifyVal = url.split('=')[1]
                this.setClassifyVal(classifyVal)
            }
            this.$router.push(url)
        },
        goWin() {
            let url = 'https://beian.miit.gov.cn/#/Integrated/index'
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    background-color: #eb6100;
    padding-left: 330px;
    padding-top: 90px;

    .foot_wrap {
        display: flex;
    }

    .logoPhone {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .logo {
            width: 126px;

            img {
                width: 100%;
            }
        }

        .phone {
            position: relative;
            top: -20px;
            display: flex;
            flex-direction: column;
            color: #fff;

            .text1 {
                font-size: 20px;
            }

            .text2 {
                font-size: 32px;
                font-weight: 700;
                color: #fff;
                letter-spacing: 2px;
                margin-top: 10px;
                margin-bottom: 5px;

            }

            .text3 {
                font-size: 20px;
            }
        }
    }



    .main_wrap {
        display: flex;
        margin-left: 10px;
        color: #fff;
        position: relative;

        dl {
            margin-left: 90px;

            &:first-child {
                margin-left: 10px;
            }

            dt {
                font-weight: normal;
                font-size: 16px;
            }

            .line {
                width: 20px;
                height: 2px;
                background: rgba(255, 255, 255, .8);
                margin-top: 35px;
                margin-bottom: 35px;
            }

            dd {
                font-size: 16px;
                margin-top: 20px;
                cursor: pointer;
            }
        }



        .qr_code {
            width: 177px;
            height: 177px;
            position: absolute;
            bottom: 98px;
            right: -98px;

            .tab {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .code {
                width: 177px;
                height: 177px;
                margin-top: 20px;
                border-radius: 4px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                }
            }


        }
    }

    .bottom_line {
        width: 1270px;
        height: 135px;
        border-bottom: 2px solid rgba(255, 255, 255, .8);
    }


}

.bottom_tip {
    width: 1270px;
    height: 92px;
    line-height: 92px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    .beian {
        cursor: pointer;
    }
}
</style>
