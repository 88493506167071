import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/joinIfami',
    name: 'joinIfami',
    component: () => import('@/views/joinIfami')
  },
  {
    path: '/loveClass',
    name: 'loveClass',
    component: () => import('@/views/loveClass')
  },
  {
    path: '/loveClassDetail',
    name: 'loveClassDetail',
    component: () => import('@/views/loveClassDetail')
  },
  {
    path: '/familyDay',
    name: 'familyDay',
    component: () => import('@/views/familyDay')
  },
  {
    path: '/familyDayDetail',
    name: 'familyDayDetail',
    component: () => import('@/views/familyDayDetail')
  },
  {
    path: '/loveShare',
    name: 'loveShare',
    component: () => import('@/views/loveShare')
  },
  {
    path: '/loveShareDetail',
    name: 'loveShareDetail',
    component: () => import('@/views/loveShareDetail')
  },
  {
    path: '/loveBank',
    name: 'loveBank',
    component: () => import('@/views/loveBank')
  },
  {
    path: '/loveBankList',
    name: 'loveBankList',
    component: () => import('@/views/loveBankList')
  },
  {
    path: '/loveBankDetail',
    name: 'loveBankDetail',
    component: () => import('@/views/loveBankDetail')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product')
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import('@/views/productDetail')
  },
  {
    path: '/brandStory',
    name: 'brandStory',
    component: () => import('@/views/brandStory')
  },
  {
    path: '/coreTechnology',
    name: 'coreTechnology',
    component: () => import('@/views/coreTechnology')
  },
  {
    path: '/responsibility',
    name: 'responsibility',
    component: () => import('@/views/responsibility')
  },
  {
    path: '/brandCultrue',
    name: 'brandCultrue',
    component: () => import('@/views/brandCultrue')
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('@/views/newsCenter')
  },
  {
    path: '/newsCenterDetail',
    name: 'newsCenterDetail',
    component: () => import('@/views/newsCenterDetail')
  },
  {
    path: '/contractUs',
    name: 'contractUs',
    component: () => import('@/views/contractUs')
  },
  {
    path: '/familyCounseling',
    name: 'familyCounseling',
    component: () => import('@/views/familyCounseling')
  },
  {
    path: '/symbolicValue',
    name: 'symbolicValue',
    component: () => import('@/views/symbolicValue')
  },
  {
    path: '/designer',
    name: 'designer',
    component: () => import('@/views/designer')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('@/views/demo')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
