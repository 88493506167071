import Vue from 'vue';
import Vuex from 'vuex';
import request from '@/utils/request';


const state = {
  classifyVal:'', //菜单值产品中心商品分类值
  spacing: '', //间距
  lineHeight:'', //行高
  phone:'', //客服电话
	baseUrl:process.env.VUE_APP_BASE_API,
};

const actions = {
  getLetterSpace({commit},data) {
    return request({
        url: `/api/common/spacing`,
        method: 'get',
        data: data
    }).then(res=>{
      console.log(res.data);
      commit('setSpacing',res.data)
    })
},
getPhone({commit},data) {
  return request({
      url: `/api/common/customerPhone`,
      method: 'get',
      data: data
  }).then(res=>{
    console.log(res.data);
    commit('setPhone',res.data.customerPhone)
  })
}
};

const mutations = {
  setClassifyVal(state, provider){
    state.classifyVal = provider
  },
  setSpacing(state, provider) {
    state.spacing = provider.spacing
    state.lineHeight = provider.rowHeight
  },
  setPhone(state, provider) {
    state.phone =  provider
  },

};



Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  mutations,
  state,
})