import request from '@/utils/request';

/* 品牌故事背景图 */
export function getBackground(params) {
    return request({
        url: `/api/about/story/background`,
        method: 'get',
        data: params
    })
}

/* 品牌故事公司介绍 */
export function companyProfile(params) {
    return request({
        url: `/api/about/story/companyProfile`,
        method: 'get',
        data: params
    })
}

/* 品牌口号 */
export function brandSlogan(params) {
    return request({
        url: `/api/about/story/brandSlogan`,
        method: 'get',
        data: params
    })
}

/* 证书 */
export function certificate(params) {
		console.log("params",params)
    return request({
        url: `/api/about/story/certificate`,
        method: 'get',
        params: params
    })
}

/* 核心技术背景图 */
export function hxBackground(params) {
    return request({
        url: `/api/about/core/technology/background`,
        method: 'get',
        data: params
    })
}

/* 核心技术 */
export function getTechnology(params) {
    return request({
        url: `/api/about/core/technology`,
        method: 'get',
        data: params
    })
}

/* 社会责任 baby can you kiss me */
export function getSocietyDuty(params) {
    return request({
        url: `/api/about/societyDuty`,
        method: 'get',
        data: params
    })
}

/* 新闻背景图 baby can you kiss me */
export function getNewBack(params) {
    return request({
        url: `/api/about/news/background`,
        method: 'get',
        data: params
    })
}

/* 新闻列表 baby can you kiss me */
export function getNewsList(params) {
    return request({
        url: `/api/about/news/newsList?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}

/* 新闻详情 baby can you kiss me */
export function getNewsDetile(params) {
    return request({
        url: `/api/about/news/newsInfo?id=${params.id}`,
        method: 'get',
        data: params
    })
}

/* 联系我们背景图 baby can you kiss me */
export function getContactBack(params) {
    return request({
        url: `/api/about/contact/background`,
        method: 'get',
        data: params
    })
}

/* 公司信息 baby can you kiss me */
export function getGongsi(params) {
    return request({
        url: `/api/about/contact/contactUs`,
        method: 'get',
        data: params
    })
}

/* 招聘信息 baby can you kiss me */
export function getRecruit(params) {
    return request({
        url: `/api/about/contact/recruit`,
        method: 'get',
        data: params
    })
}

/* 品牌合作 baby can you kiss me */
export function brandCooperation(params) {
    return request({
        url: `/api/about/contact/brandCooperation`,
        method: 'get',
        data: params
    })
}

/* 合作伙伴 baby can you kiss me */
export function loadContact(params) {
    return request({
        url: `/api/about/contact`,
        method: 'get',
        data: params
    })
}

/* 品牌加入 baby can you kiss me */
export function brandJoining(params) {
    return request({
        url: `/api/about/contact/brandJoining`,
        method: 'post',
        data: params
    })
}

/* 咨询 baby can you kiss me */
export function sendGuidance(params) {
    return request({
        url: `/api/about/contact/guidance`,
        method: 'post',
        data: params
    })
}


















