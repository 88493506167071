<template>
  <div>
    <div class="banner swiper_warp">
      <div class="swiper-container banner-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index" @click="goTo(item)">
            <img class="banner_img" v-if="item.type == 1" v-lazy="item.image" alt="" srcset="">
            <video class="banner_video" v-if="item.type == 2" :src="item.image" :autoplay="true" muted loop></video>
          </div>
        </div>
        <div class="swiper-pagination banner-pagination"></div>
      </div>
    </div>
    <div class="title_product">
      <div class="title">
        <span>产品推荐</span>
        <span>RECOMMENDED PRODUCTS</span>
      </div>
      <div class="btn" @click="$router.push('/product')">READ MORE</div>
    </div>
    <div class="banner-my">
      <div
        class="banner-list mySwiper2 mySwiper swiper-initialized swiper-vertical swiper-pointer-events swiper-backface-hidden">
        <div class="swiper-wrapper" id="swiper-wrapper-2f15e2eb761f213a" aria-live="polite">
          <!-- <div class="swiper-slide swiper-slide-active" role="group" aria-label="1 / 9">Slide 1</div>
              <div class="swiper-slide swiper-slide-next" role="group" aria-label="2 / 9">Slide 2</div> -->
          <div class="swiper-slide" role="group" :aria-label="`${index + 1} / ${product.length}`"
            v-for="(item, index) in product" :key="index" @mouseenter="hoverNow(index)">
            <div class="item-banner" :class="{ 'bannerNow': currentIndex == index }">
              <div class="title">
                <span class="nameT">{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-top" tabindex="0" role="button" aria-label="Previous slide"
          aria-controls="swiper-wrapper-1011f0bdb210502456" aria-disabled="false">
          <img src="../assets/images/icon-10.png" alt="" srcset="">
        </div>
        <div class="btn-bottom" tabindex="0" role="button" aria-label="Next slide"
          aria-controls="swiper-wrapper-1011f0bdb210502456" aria-disabled="false">
          <img src="../assets/images/icon-10.png" alt="" srcset="">
        </div>
      </div>
      <div class="box">
        <img class="mainImg" :src="item.mainImage" alt="" srcset="" v-for="(item, index) in product" :key="index"
          @click="$router.push('/productDetail?id=' + item.id+'&isHome=true')" v-show="currentIndex == index">
      </div>
    </div>
    <div class="imgList" v-for="(item, index) in bannerList2" :key="index">
      <div class="item">
        <img class="mainImg wow scaleStar" v-lazy="item.image" alt="" srcset="">
        <div class="content">
          <div class="title wow translateStar">
            <img src="@/assets/logo1.png" alt="" srcset="">
            <span>{{ item.title }}</span>
          </div>
          <div class="desc wow translateStar">{{ item.content }}</div>
          <div class="btn wow translateStar" @click="goTo(item)">立即前往</div>
        </div>
      </div>
    </div>
    <div class="imgList">
      <div class="item video">
        <video id="video" :src="videoUrl" :poster="poster" controls ref="video"></video>
        <div class="shadowBox" @click="playOrPause" v-if="!isPlay">
          <img src="@/assets/images/playVideo2.png" alt="" srcset="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { getBanner, getNavigation, getRecommend,getVideo } from "@/api/home";
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"
export default {
  name: 'HomeView',
  data() {
    return {
      bannerList: [],
      bannerList2: [],
      mySwiper: null,
      mySwiper2: null,
      videoUrl: '',
      poster: '',
      isPlay: false,  //是否播放
      product: [],
      currentIndex: 0,  //hover下标
      transformNum: 0,
    };
  },
  mounted() {
    // 首页轮播图
    this.loadBanner();
    this.loadProduct();
    this.loadNavigation();
		this.loadVideo();

    // window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域

  },
  methods: {
    // scrollHandle() {
    //   const offset = this.$el.getBoundingClientRect();
    //   const offsetTop = offset.top;
    //   const offsetBottom = offset.bottom;
    //   // const offsetHeight = offset.height;
    //   // 进入可视区域
    //   // console.log(offsetTop,offsetBottom)
    //   this.isShowAnimation = offsetTop <= window.innerHeight && offsetBottom >= 0  ?true:false
    // },
    goTo(item) {
      console.log(item);
      if (item.isOpen == 1) {
        if (item.openType != 3) {
          console.log(item.openParam);
          this.$router.push(item.openParam)
        } else {
          location.href = item.openParam
        }
      }

    },
    hoverNow(idx) {
      this.currentIndex = idx
    },
    initJy(test_str) {
      var index = test_str.lastIndexOf('.') // 获取指定字符串最后一次出现的位置，返回index
      var str = test_str.substr(index + 1) // substr(start, length) 抽取从start下标开始的length个字符，返回新的字符串
      return this.isImageType(str)
    },
    isImageType(str) {
      // toLowerCase() 将字符串转换为小写，返回一个新的字符串
      return ['mp4'].indexOf(str.toLowerCase()) !== -1
    },
    // 加载轮播图
    loadBanner() {
      let that = this;
      getBanner().then((res) => {
        if (res.code + '' === '200') {
          let list = res.data;
          this.bannerList = list;
          setTimeout(() => {
            this.initSwiper();
          }, 100)
        }
      });
    },
    // 加载推荐商品
    loadProduct() {
      let that = this;
      getRecommend().then((res) => {
        if (res.code + '' === '200') {
          let list = res.data;
          this.product = list;
          setTimeout(() => {
            this.initSwiper2();
          }, 100)
        }
      });
    },

    // 加载主要内容
    loadNavigation() {
      let that = this;
      getNavigation().then((res) => {
        if (res.code + '' === '200') {
          let list = res.data;
          list.forEach(item => {
            if (item.content) {
              item.content = item.content.replaceAll('\r', '</br>')
            }
          })
          this.bannerList2 = list;
          this.$nextTick(() => {
            new this.$wow.WOW({ live: false }).init()
          })
        }
      });
    },
		// 加载主要内容
    loadVideo() {
      let that = this;
      getVideo().then((res) => {
        if (res.code + '' === '200') {
				this.videoUrl = res.data.url;
         this.poster = res.data.image;   
        }
      });
    },
    //  开始/ 暂停事件
    playOrPause() {
      if (!this.isPlay) {
        this.$refs.video.play()
      }
      this.isPlay = true;
    },
    initSwiper() {
      this.mySwiper = new Swiper('.banner-container', {
        //direction: 'vertical', // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: true,
        height: 554,
        // width: window.innerWidth,
        loop: true, // 循环模式选项

        // 如果需要分页器
        pagination: {
          el: '.banner-pagination',
          clickable: true, // 分页器可以点击
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.arrow-right',
          prevEl: '.arrow-left',
        },
        on: {
          resize: () => {
            setTimeout(() => {
              this.mySwiper.update()
            }, 100)
          },
        },
      });

    },
    initSwiper2() {
      this.mySwiper2 = new Swiper(".mySwiper2", {
        direction: "vertical",
        slidesPerView: 7,
        spaceBetween: 4,
        pagination: {
          el: ".swiper-pagination2",
          type: "fraction",
        },
        navigation: {
          nextEl: ".btn-bottom",
          prevEl: ".btn-top",
        },
        on: {
          resize: () => {
            setTimeout(() => {
              this.mySwiper2.update()
            }, 100)
          },
        },
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.title_product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 170px;
  padding-top: 80px;
  padding-left: 360px;
  padding-right: 330px;

  span:first-child {
    font-size: 36px;
  }

  span:last-child {
    font-size: 30px;
    color: #404040;
    margin-left: 30px;
  }

  .btn {
    padding: 5px 25px;
    border-radius: 10px;
    border: 1px solid #404040;
    color: #404040;
    font-size: 15px;
  }

  .btn:hover {
    background: #eb6100;
    border: 1px solid #eb6100;
    color: #fff;
  }
}

.banner-my {
  position: relative;

  .box {
    position: absolute;
    top: 0;
    right: 0;
    width: 1594px;
    height: 784px;
    z-index: 3;
    cursor: pointer;



    .mainImg {
      width: 1594px;
      height: 784px;
      object-fit: cover;
      cursor: pointer;
      transition: all .5s;
    }
  }

  .banner-list {
    position: relative;
    flex: 1;
    height: 784px;
    overflow: hidden;

    .btn-top,
    .btn-bottom {
      overflow: hidden;
      z-index: 111;
      position: absolute;
      width: 326px;
      height: 45px;
      border: 0;
      background: rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: all .5s ease;
      text-align: center;

      img {
        width: 20px;
      }
    }

    .btn-top {
      top: 0;
      left: 0;
      transform: translate(0, -100%);
    }

    .btn-bottom {
      bottom: 0;
      left: 0;
      transform: translate(0, 100%);
    }

    &:hover .btn-top {
      transform: translate(0, 0);

      img {
        transform: translate(-50%, -50%) rotate(-90deg);
        animation: gotTop 2s linear infinite;
      }
    }

    &:hover .btn-bottom {
      transform: translate(0, 0);

      img {
        transform: translate(-50%, -50%) rotate(90deg);
        animation: gotBottom 2s linear infinite;
      }
    }

    @keyframes gotTop {
      0% {
        transform: translate(-50%, 25px) rotate(-90deg);
      }

      100% {
        transform: translate(-50%, -25px) rotate(-90deg);
      }
    }

    @keyframes gotBottom {
      0% {
        transform: translate(-50%, -25px) rotate(90deg);
      }

      100% {
        transform: translate(-50%, 25px) rotate(90deg);
      }
    }

    .item-banner {
      width: 326px;
      height: 108px;
      background: #f6f2e9;
      cursor: pointer;
      position: relative;

      .title {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .nameT {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: -36px;
            top: 50%;
            width: 16px;
            height: 1px;
            margin-top: -0.5px;
            background: #fff;
            transform: translate(-20px, 0);
            transition: all 0.6s ease;
          }

          &::after {
            content: "";
            position: absolute;
            right: -36px;
            top: 50%;
            width: 16px;
            height: 1px;
            margin-top: -0.5px;
            background: #fff;
            transform: translate(20px, 0);
            transition: all 0.6s ease;
          }
        }
      }

      &:hover {
        background: #eb6100;
        color: #fff;
      }

      &:hover .nameT {
        &::before {
          transform: translate(0, 0);
        }

        &::after {
          transform: translate(0, 0);
        }

      }


    }

    .bannerNow {
      background: #eb6100;
      color: #fff;

      .title {
        .nameT {
          &::before {
            transform: translate(0, 0);
          }

          &::after {
            transform: translate(0, 0);
          }

        }
      }

    }

  }

}



.banner {
  height: 890px;

  .banner_img {
    width: 100%;
    height: 890px;
    object-fit: cover;
  }

  .banner_video {
    width: 100%;
    height: 890px;
    background-color: #eee2d4;
    object-fit: cover;
  }
}

.imgList {
  .item {
    height: 730px;
    margin-top: 60px;
    position: relative;
    overflow: hidden;

    .mainImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      left: 328px;
      top: 50%;
      transform: translateY(-50%);

      .title {
        display: flex;
        align-items: center;
        font-size: 40px;

        span {
          position: relative;
          top: 6px;
        }

        img {
          width: 125px;
          margin-right: 30px;
        }
      }

      .desc {
        margin-top: 10px;
        font-size: 18px;
      }

      .btn {
        width: 164px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #000;
        font-size: 18px;
        margin-top: 70px;
        cursor: pointer;

        &:hover {
          background: #eb6100;
          border: 1px solid #eb6100;
          color: #fff;
        }
      }
    }
  }

  .video {
    height: 1040px;
    position: relative;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .shadowBox {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .5);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 115px;
        height: 115px;
      }
    }
  }
}
</style>
