import request from '@/utils/request';

/* 轮播图 */
export function getBanner(params) {
    return request({
        url: `/api/home/banner`,
        method: 'get',
        data: params
    })
}

/* 导航 */
export function getNavigation(params) {
    return request({
        url: `/api/home/navigation`,
        method: 'get',
        data: params
    })
}
// 推荐产品
export function getRecommend(params) {
    return request({
        url: `/api/home/recommend`,
        method: 'get',
        data: params
    })
}
// 宣传视频
export function getVideo(params) {
    return request({
        url: `/api/home/video`,
        method: 'get',
        data: params
    })
}
