import request from '@/utils/request';

/* 背景图 */
export function proBack(params) {
    return request({
        url: `/api/productCenter/background`,
        method: 'get',
        data: params
    })
}



/* 菜单包含详情 */
export function getUnified(params) {
    return request({
        url: `/api/productCenter/unified`,
        method: 'get',
        data: params
    })
}

/* 产品列表 */
export function getProductList(params) {
    return request({
        url: `/api/productCenter/productList?pageNum=${params.pageNum}&pageSize=${params.pageSize}&type=${params.type}&category=${params.category}`,
        method: 'get',
        data: params
    })
}

/* 产品详情 */
export function getProductInfo(params) {
    return request({
        url: `/api/productCenter/productInfo?id=${params.id}`,
        method: 'get',
        data: params
    })
}

/* 菜单 */
export function getClassify(params) {
    return request({
        url: `/api/productCenter/classify?type=${params.type}&parentId=${params.parentId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'get',
        data: params
    })
}
