<template>
  <div id="app" class="isPc">
    <Header v-if="header_show" />
    <router-view class="main_warp" @public_header="public_header" @public_footer="public_footer" />
    <Footer v-if="footer_show" />
  </div>
</template>
<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  components: {
    Header,
    Footer
  },
  name: 'App',
  data() {
    return {
      header_show: true,
      footer_show: true
    }
  },
  watch: {
    '$route'(to, from) {
      this.activeIndex = to.path;
      /** 纯pc */
      if (this.activeIndex == '/login') {
        this.header_show = false;
        this.footer_show = false;
      } else {
        this.header_show = true;
        this.footer_show = true;
      }
    }
  },
  mounted() {
    /** 自适应 */
    if (this.$route.path == '/login') {
      console.log("=================进来了22")
      this.header_show = false;
      this.footer_show = false;
    } else {
      this.header_show = true;
      this.footer_show = true;
    }
    // this.$store.dispatch('getLetterSpace')
    this.$store.dispatch('getPhone')
  },
  methods: {
    public_header(bool) {
      console.log("进来了===========")
      this.header_show = bool;
    },
    public_footer(bool) {
      this.footer_show = bool;
    }
  }
}
</script>
<style>
@import '@/assets/css/common.css';

#app {
  /* font-family: 'Microsoft YaHei'; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  /* background-color: #42b983; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.main_warp {
  flex: 1;
}
</style>
