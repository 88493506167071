import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//这里的Vue要做修改，等会就知道了
Vue.use(ElementUI);

Vue.prototype.$phone = function (phone) {
  if (phone) {
    return phone.substr(0, 3) + ' ' + phone.substr(3, 4) + ' ' + phone.substr(7, 4);
  }
}
Vue.prototype.$fixUrl = function (str,type=1) {
  if (type==1) {
    return 'http://'+str;
  }else if(type==2){
		return this.$store.state.baseUrl+'/upload/'+str;
	}
}
Vue.prototype.$richImg = function (richtext) {
  if (!richtext) {
    return;
  }
  const regex = new RegExp('<img', 'gi');
  return richtext.replace(/(\<img|\<p)/gi, function ($0, $1) {
    return {
      "<img": '<img style="max-width:100%;height:auto;"',
      "<p": '<p ',
      "<article": "<div",
      "</article": "</div",
      "<header": "<div",
      "</header": "</div"
    }[$1];
  });
}

import wow from 'wowjs'
Vue.prototype.$wow = wow


//引入vue懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
// import loading from '@/assets/loadImg/load.gif'
// //注册图片懒加载  
// Vue.use(VueLazyload, {
//   // preLoad: 1.3,
//   error: '@/assets/loadImg/loadfail.png',//图片错误的替换图片路径(可以使用变量存储)
//   loading: loading,//正在加载的图片路径(可以使用变量存储)
//   // attempt: 1
// })

// import 'lib-flexible/flexible.js'

Vue.config.productionTip = false
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
